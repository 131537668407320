

































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { mixins } from "vue-class-component";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import AbibaroRequestMixin from "@/mixins/Request/AbibaroRequestMixin";
import { IAbibaroStatus } from "@/interfaces/Student/IAbibaroStatus";
@Component({
  components: { FscPageHeader, SaveButton }
})
export default class AbibaroStudentRegistration extends mixins(StudentRequestMixin, AbibaroRequestMixin) {
  public name = "AbibaroStudentRegistration";

  private get educations(): Array<any> {
    return this.getLicenceClasses;
  }

  public activeClassStatus = 0;
  public tabClass = "";
  public loading = false;
  public student: any = null;
  public concept: any = null;
  public region: any = null;
  public variant: any = null;
  public language: any = null;
  public addGermanTranslation = false;
  public costDetails = "";
  public currentEducationId = 0;
  public currentEducationProgress: IAbibaroStatus = {
    fillingDate: "",
    registeredInAbibaro: false,
    progressPercentages: 0,
    instructorRatingPercentage: 0,
    learnerRatingPercentage: 0,
    numberOfTasks: 0,
    tasksStarted: 0,
    studentEducationId: 0
  };
  public dataLoaded = false;
  public label = this.$t("user.fee");
  public variants: Array<any> = [];
  public languages: Array<any> = [];


  public get numberOfTasksPercentage() {
    if (this.currentEducationProgress.numberOfTasks == 0) {
      return 0;
    }
    return (this.currentEducationProgress.tasksStarted / this.currentEducationProgress.numberOfTasks) * 100;
  }

  public get btnSaveDisabled() {
    if (!this.concept || !this.region || !this.variant || !this.language || this.hasSource || this.abibaroIsLoading || this.abibaroOtherLoading) {
      return true;
    }
    return false;
  }

  public get studentName() {
    if (this.student) {
      return this.student.firstName + " " + this.student.lastName;
    }
    return "<Vorname> <Nachname>";
  }

  public getStudent() {
    return (this.student as any) || null;
  }

  public activeClassStatusTabShow(index: any, activeTab: any) {
    return index === activeTab;
  }

  public async changeActiveClassStatus(index: number, education: any) {
    if (education) {
      this.tabClass = `fsc-student-info`;
    } else {
      this.tabClass = `fsc-student-opc`;
    }
    this.activeClassStatus = index;
    this.currentEducationId = this.educations[index].studentEducationId;
    this.hasSource = false;
    await this.getAbibaroRegistrationQrCode(this.currentEducationId);
    const { status, data } = await this.getAbibaroDetails(this.currentEducationId);
    let variantId = null;
    let languageId = null;
    if (!this.hasSource || status !== 200) {
      this.region = this.educations[index] && this.educations[index].regionList?.length === 1 ? this.educations[index].regionList[0] : null;
      this.concept = this.educations[index].conceptList.find((concept: any) => concept.default) || null;

      this.variants = [];
      this.languages = [];
      variantId = null;
      languageId = null;

    } else {
      this.concept = this.educations[index].conceptList.find((concept: any) => concept.conceptId === data.conceptId);
      this.region = this.educations[index].regionList.find((region: any) => region.regionId === data.regionId);

      variantId = data.variantId;
      languageId = data.languageId;
    }

    // even basic info fetch failed some schools have default concepts so load depending on that lang and variants here
    if (this.concept) {
      await this.loadVariantsAndLanguages(this.concept.conceptId);
    }
    this.setSelectedVariantAndLanguage(variantId, languageId);

    this.addGermanTranslation = false;

    this.setCurrentEducationProgress();

  }

  public async onRegisterStudent() {
    if (!this.getStudent) return;
    const data = {
      conceptId: this.concept.conceptId,
      regionId: this.region.regionId,
      studentEducationId: this.educations[this.activeClassStatus].studentEducationId,
      salutations: this.student?.title,
      firstName: this.student?.firstName,
      lastName: this.student?.lastName,
      mobile: this.student?.mobilePhone,
      dob: this.student?.birthDate,
      variantId: this.variant.variantId,
      languageId: this.language.languageId,
      addGermanTranslation: this.addGermanTranslation
    };
    await this.registerStudentAction({
      resource: "abibaro/register-student",
      id: +this.student?.id, //fsc educationID
      data: data
    });
    await this.getAbibaroRegistrationQrCode(this.currentEducationId);
  }

  public async fetchData() {
    this.hasSource = false;
    await this.fetchStudent(Number(this.$route.params.id));
    await this.getAbibabroLicenceClassesAction({
      resource: "abibaro/get-region-and-concept",
      id: this.student.id
    });

    await this.getStudentEducationsAbibaroStatus(Number(this.$route.params.id));

    if (this.educations && this.educations.length > 0 && this.educations[0]?.conceptList) {
      this.concept = this.educations[0].conceptList.find((concept: any) => concept.default);
      this.region = this.educations[0].regionList?.length === 1 ? this.educations[0].regionList[0] : null;
    }

    if (this.educations && this.educations.length > 0) {
      let languageId = null;
      let variantId = null;
      this.currentEducationId = this.educations[0].studentEducationId;
      await this.getAbibaroRegistrationQrCode(this.currentEducationId);
      const { status, data } = await this.getAbibaroDetails(this.currentEducationId);
      if (this.hasSource && status === 200) {
        this.concept = this.educations[0].conceptList.find((concept: any) => concept.conceptId === data.conceptId);
        this.region = this.educations[0].regionList.find((region: any) => region.regionId === data.regionId);

        variantId = data.variantId;
        languageId = data.languageId;
      } else {
        this.region = this.educations[0] && this.educations[0].regionList?.length === 1 ? this.educations[0].regionList[0] : null;
        this.concept = this.educations[0].conceptList.find((concept: any) => concept.default) || null;

        variantId = null;
        languageId = null;
      }

      if (this.concept) {
        await this.loadVariantsAndLanguages(this.concept.conceptId);
      }
      this.setSelectedVariantAndLanguage(variantId, languageId);
    }

    {
      const { status, data } = await this.getAbibaroCostDetails();
      if (status === 200) {
        this.costDetails = data;
      } else {
        this.costDetails = "";
      }
    }

    this.setCurrentEducationProgress();

  }

  private setCurrentEducationProgress() {
    for (let i in this.studentEducationsAbibaroStatus) {
      if (this.studentEducationsAbibaroStatus[i].studentEducationId == this.currentEducationId) {
        this.currentEducationProgress = this.studentEducationsAbibaroStatus[i];
        break;
      }
    }
  }

  protected async onConceptChange(selectedOption: any) {
    await this.loadVariantsAndLanguages(selectedOption.conceptId);
  }

  protected async loadVariantsAndLanguages(conceptId: any) {

    {
      const { status, data } = await this.getAbibaroVariants(conceptId);
      if (status === 200) {
        this.variants = data;
      } else {
        this.variants = [];
      }
    }

    {
      const { status, data } = await this.getAbibaroLanguages(conceptId);
      if (status === 200) {
        this.languages = data;
      } else {
        this.languages = [];
      }
    }

  }

  private setSelectedVariantAndLanguage(variantId: any, languageId: any) {
    this.variant = this.variants.find((variant: any) => variant.variantId === variantId);
    this.language = this.languages.find((language: any) => language.languageId === languageId);
  }

  private get germanLanguageSelected(): boolean {
    return this.language == null || this.language.languageId == 0;
  }

  protected onLanguageChange(selectedOption: any) {
    this.addGermanTranslation = false; // reset only
  }

  public mounted() {
    this.fetchData();
    setTimeout(() => {
      this.dataLoaded = true;
    }, 600);
  }
}
